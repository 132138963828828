import React,{useState} from 'react';
import { Link } from 'react-router-dom';
import './Navbar.css';

function Navbar() {
    const [click, setClick] = useState(false);
    const handleClick = () => setClick(!click);
    const closeMobileMenu = () =>setClick(false);
  return (
    <>
    {/*header 1 */}
        <nav className='header1'>
            <div className='contact'>
                <div className='content'>
                    <li className='nav-item'>
                        <i className={ 'fas fa-phone' } />
                        <p>0703116873</p>
                    </li>
                    <li className='nav-item'>
                        <i className={ 'fas fa-envelope' } />
                        <p>nyansiongomaternity@gmail.com</p>
                    </li>
                                 
                </div>
            </div>
            
        </nav>
        <nav className="header">
            <div className="header-container">
                <Link   to="/" className="header-logo">
                    <img src='../Images/NMNH.png'></img>
                </Link>
                <div className='menu-icon' onClick={handleClick}>
                    {/*hambuger menu*/}
                    <i className={click ? 'fas fa-times' : 'fas fa-bars'} />
                </div>
                <ul className={click ? 'nav-menu active' : 'nav-menu'}>
                    <li className='nav-item'>
                        <Link to='/' className='nav-links' onClick={closeMobileMenu}>
                            Home
                        </Link>
                    </li>
                    <li className='nav-item'>
                        <Link to='/about-us' className='nav-links' onClick={closeMobileMenu}>
                            About Us
                        </Link>
                    </li>
                    <li className='nav-item'>
                        <Link to='/services' className='nav-links' onClick={closeMobileMenu}>
                            Services
                        </Link>
                    </li>
                    <li className='nav-item'>
                        <Link to='/blog' className='nav-links' onClick={closeMobileMenu}>
                            Blog
                        </Link>
                    </li>
                    <li className='nav-item'>
                        <Link to='/contact' className='nav-links' onClick={closeMobileMenu}>
                            Contact Us
                        </Link>
                    </li>
                </ul>
            </div>
            
        </nav>
        <nav className='animation'>
            <div className="move">
                    <p>Nyansiongo Maternity & Nursing Home. Where healing meets hope.</p>
            </div>
        </nav>
        
    </>
  )
}

export default Navbar
