import React, { useEffect } from 'react';
import Button from '../../components/Button';
import '../Styles/Home.css';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import AOS from 'aos';
import 'aos/dist/aos.css';

const Home = () => {
    useEffect(() => {
        AOS.init({
            offset: 200, duration: 1000,
        });

        // Tawk.to Script
        var Tawk_API = Tawk_API || {}, Tawk_LoadStart = new Date();
        (function() {
            var s1 = document.createElement("script"), s0 = document.getElementsByTagName("script")[0];
            s1.async = true;
            s1.src = 'https://embed.tawk.to/667809d4eaf3bd8d4d137e78/1i12ecqg4';
            s1.charset = 'UTF-8';
            s1.setAttribute('crossorigin', '*');
            s0.parentNode.insertBefore(s1, s0);
        })();
    }, []);

    const settings = {
        fade: true,
        dots: true,
        infinite: true,
        speed: 3000,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        autoplay: true,
        autoplaySpeed: 1000,
    };

    return (
        <section className='home-container'>
            <div className='home'>
                <div className='hero-container' data-aos="fade-down">
                    <h1>Healthcare you can trust</h1>
                    <p>Nyansiongo Maternity & Nursing Home is an accredited and preferred medical institution that offers accessible, sustainable, affordable and high quality healthcare services. Our qualified staff is more than ready to serve you in a friendly manner.</p>
                </div>
                <Button />
            </div>
            {/*about us section*/}
            <div className='about' data-aos="fade-up">
                <h2 className='heading'>about us</h2>
                <div className='about-us'>
                    <div className='image' data-aos="fade-left">
                        <img src='../Images/about4.png' style={{ width: 40 + 'em' }} alt="About Us" />
                    </div>
                    <div className='content' data-aos="fade-right">
                        <h2>why choose us?</h2><br />
                        <p>Nyansiongo Maternity & Nursing Home is a beacon of healthcare excellence nestled in the heart of our community. With a legacy spanning decades, we have been dedicated to providing compassionate and comprehensive medical services to individuals and families in need.</p>
                        <Button />
                    </div>
                </div>
            </div>
            {/*our services section */}
            <div className='services' data-aos="fade-up">
                <h2 className='heading'>Our Services</h2>
                {/*services slider */}
                <div>
                    <Slider {...settings} style={{ margin: 0 + 'em' }}>
                        <div className='our-services'>
                            <div className='service-item'>
                                <div className='image'>
                                    <img src='../Images/lab.jpg' style={{ width: 40 + 'em' }} alt='Lab' />
                                </div>
                                <div className='content'>
                                    <h3>Laboratory Services</h3>
                                    <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Aspernatur temporibus distinctio sint ipsa at quidem animi, accusantium, dolorem, ut expedita nesciunt. Odit hic quod delectus illo incidunt rem neque quae.</p>
                                    <Button />
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className='service-item'>
                                <div className='image'>
                                    <img src='../Images/maternity.jpg' style={{ width: 40 + 'em' }} alt='Maternity' />
                                </div>
                                <div className='content'>
                                    <h3>Maternity Services</h3>
                                    <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Aspernatur temporibus distinctio sint ipsa at quidem animi, accusantium, dolorem, ut expedita nesciunt. Odit hic quod delectus illo incidunt rem neque quae.</p>
                                    <Button />
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className='service-item'>
                                <div className='image'>
                                    <img src='../Images/pharmacy.jpg' style={{ width: 40 + 'em' }} alt='Pharmacy' />
                                </div>
                                <div className='content'>
                                    <h3>Pharmacy Services</h3>
                                    <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Aspernatur temporibus distinctio sint ipsa at quidem animi, accusantium, dolorem, ut expedita nesciunt. Odit hic quod delectus illo incidunt rem neque quae.</p>
                                    <Button />
                                </div>
                            </div>
                        </div>
                    </Slider>
                </div>
            </div>
        </section>
    );
}

export default Home;
