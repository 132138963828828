import React from 'react';
import './button.css';
import { Link } from 'react-router-dom';


function Button() {
    let currentYear = new Date().getFullYear()
  return (
    <section className='button'>
        <div className='button-container'> 
        <button>LOAD MORE</button>  
       
        
      
        </div>
    </section>
    
  )
}

export default Button
