import React from 'react';
import Button from '../../components/Button';
import '../Styles/Home.css';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

   
    const settings = {
      fade: true,
      dots: true,
      infinite: true,
      speed: 3000,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows:false,
      autoplay: true,
      autoplaySpeed: 1000,
      
      
    };
function About(){
    
    return (
        <section className='about-container'>                  
            {/*About-us page */}
            <div className='about-us-page'>
                {/*mission, vision and motto slider */}
                <div className='' >
                    <Slider {...settings} style={{margin:0 +'em'}}>
                        <div className='us'>
                            <div className='about-us-item'>                                
                                <div className='content'>
                                    <h3>Our Mission</h3>
                                    <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Aspernatur temporibus distinctio sint ipsa at quidem animi, accusantium, dolorem, ut expedita nesciunt. Odit hic quod delectus illo incidunt rem neque quae.</p>{" "}
                                    
                                </div>
                            </div>                  
                        
                        </div>
                        <div>
                            <div className='about-us-item'>                      
                                <div className='content'>
                                    <h3>Our Vision</h3>
                                    <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Aspernatur temporibus distinctio sint ipsa at quidem animi, accusantium, dolorem, ut expedita nesciunt. Odit hic quod delectus illo incidunt rem neque quae.</p>
                                    
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className='about-us-item'>                            
                                <div className='content'>
                                    <h3>Our Motto</h3>
                                    <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Aspernatur temporibus distinctio sint ipsa at quidem animi, accusantium, dolorem, ut expedita nesciunt. Odit hic quod delectus illo incidunt rem neque quae.</p>
                                    
                                </div>
                            </div>
                        </div>
                    </Slider> 
                </div>
                                                          
            </div>
            <div className='about'>
                <h2 className='heading'>about us</h2>
                <div className='about-us2'>
                    <div className='image'>
                        <img src='../Images/about3.jpg' style={{width:30 +'em'}}></img>
                    </div>                 
                   
                    <div className='content'>
                        <h2>Who We Are</h2><br></br>
                        <p>Nyansiongo Maternity & Nursing Home is a beacon of healthcare excellence nestled in the heart of our community. With a legacy spanning decades, we have been dedicated to providing compassionate and comprehensive medical services to individuals and families in need.</p>
                        <p>At Nyansiongo Maternity & Nursing Home, we understand the importance of accessible, sustainable, and high-quality healthcare. Our team of skilled professionals is committed to delivering personalized care and support to every patient who walks through our doors.</p>
                        <p>Whether you're welcoming a new addition to your family or seeking treatment for a medical concern, Nyansiongo Maternity & Nursing Home is here to provide you with the highest standard of care. We invite you to experience the difference that compassionate and comprehensive healthcare can make in your life.</p>    
                    </div>
                    
                </div>                              
            </div>
            <div className='values'>
                <h2 className='heading'>our values</h2>
                    <div className='icon'>                        
                        <img src='../Images/values.png'></img>
                    </div>
                    <div className='content'>
                        <p><strong>Dedication:</strong> We are dedicated to offering patients and their families efficient service and great value for their money.</p>
                        <p><strong>Empathy:</strong> We are devoted to providing a warm, friendly and caring environment in which patients can recover.</p>
                        <p><strong>Inspiration:</strong> We are an organization that inspires our staff to achieve the extraordinary and develop the best careers.</p>{" "}
                    </div>

            </div>          
            

            
        </section>
        
        
        
    );
}
export default About;