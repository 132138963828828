import React from 'react';
import Button from '../../components/Button';
import '../Styles/Home.css';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

   
    const settings = {
      fade: true,
      dots: true,
      infinite: true,
      speed: 3000,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows:false,
      autoplay: true,
      autoplaySpeed: 1000,
      
      
    };
function Contact(){
    
    return (
        <section className='contact-container'>
            {/*location map */}
            <div className='contact'>
                <div className='map'>
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3989.4652097995136!2d35.01299167372442!3d-0.7622889352753149!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x182b6dcd4409fe3f%3A0x871447532429b319!2sNyansiongo%20maternity%20and%20nursing%20home!5e0!3m2!1sen!2ske!4v1712738247133!5m2!1sen!2ske"   allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                </div>
                <h2 className='heading'>Contact Us</h2>
                <div className='contact-us row'>
                    <div className='form col-7 '>
                        <form>
                            <p className='text-danger'><em>All fields are required</em></p>
                            <label>Name</label>
                            <input type='text' className='form-control' required placeholder='Your Name'></input>
                            <label>Email</label>
                            <input type='email' className='form-control' required placeholder='Your Email'></input>
                            <label>Subject</label>
                            <input type='text' className='form-control' required placeholder='Subject'></input>
                            <label>Message</label>
                            <input type='textarea' className='form-control' required placeholder='Message'></input>
                            <input type='submit' className='submit' value='submit'></input>
                        </form>
                    </div>
                    <div className='visiting-hrs col-3'>
                        <h3>visiting hours</h3>
                        <p>monday-sunday</p>
                        <hr></hr>
                        <p>Afternoon:12:00pm - 1:00pm</p>
                        <hr></hr>
                        <p>Evening:2:00pm - 4:00pm</p>
                        <div className='phone'>
                            <i className={ 'fas fa-phone' } />
                            <p>0712688425</p>
                        </div>
                        <div className='phone'>
                            <i className={ 'fas fa-envelope' } />
                            <p>nyansiongomaternity@gmail.com</p>
                        </div>
                        
                    </div>                    
                </div>
            </div>     
                             
            

            
        </section>
        
        
        
    );
}
export default Contact;