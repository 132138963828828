import React from 'react';
import './Footer.css';
import { Link } from 'react-router-dom';


function Footer() {
    let currentYear = new Date().getFullYear()
  return (
    <section className='footer'>
        <div className='footer-container'>
        
        {/*footer links */}
        <div className='footer-links'>
            
            <div className='footer-link-wrapper'>
                <div className='footer-logo'>
                    <Link   to="/" className="header-logo">
                        <img src='../Images/NMNH.png'></img>
                    </Link>
                    <p>Nyansiongo Maternity & Nursing Home is an accredited and preferred medical institution that offers accessible, sustainable, affordable and high quality healthcare services. Our qualified staff is more than ready to serve you in a friendly manner.</p>
                </div>
                
                
            </div>
            <div className='footer-link-wrapper'>
                <div className='footer-link-items'>
                    <h2>Quick Links</h2>
                    <Link to='/'>Home</Link>
                    <Link to='/about-us'>About Us</Link>
                    <Link to='/services'>Services</Link>
                    <Link to='/blog'>Blog</Link>
                    <Link to='/contact-us'>Contact Us</Link>
                </div>
            </div>
            <div className='footer-link-wrapper'>
                <div className='footer-link-items'>
                    <h2>Contact Us</h2>
                    <div className="contact-item">
                        <i className={ 'fas fa-envelope' } /><Link to='/'>nyansiongomaternity@gmail.com</Link>
                    </div>
                    <div className="contact-item">
                        <i className={ 'fas fa-phone' } /><Link to='/'>0703116873</Link>
                    </div>
                    <div className="contact-item">
                        <i className={ 'fas fa-map-marker' } /><Link to='/'>Nyansiongo Maternity & Nursing Home. Kijauri, Nyansiongo</Link>
                    </div>
                </div>

            </div>
        </div>
        {/*website-rights*/}
        <div className='footer-copyright'>
            
            <small className='website-rights'>Copyright &copy; {currentYear} Nyansiongo Maternity & Nursing Home | Powered By <Link to='https://angelanyabuti.netlify.app/'>Angela Nyabuti</Link></small>
        </div>
        
      
    </div>
    </section>
    
  )
}

export default Footer
