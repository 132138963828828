import React from 'react';
import Button from '../../components/Button';
import '../Styles/Home.css';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import AOS from 'aos';
import 'aos/dist/aos.css';

   
            AOS.init({
                offset: 200,duration: 1000,
            });
  
    
function Service(){
   return (
      <section className='services-container'>
        <div className='ourservices'>
          <h2 className='heading'>Our Services</h2> 
          <p>Home / Services</p>         
        </div>
        <div className='services-card'>
          <div className='card'>
            <div className='card-body'>
              <div className='service'>
                <i class="fas fa-thin fa-prescription-bottle-medical"></i>
                <p className='service-content'>Pharmarcy</p>
              </div>
              <div className='service'>
                <i class="fas fa-light fa-person-breastfeeding"></i>
                <p className='service-content'>Maternity</p>
              </div>
              <div className='service'>
                <i class="fas fa-thin fa-stethoscope"></i>
                <p className='service-content'>Laboratory</p>
              </div>
            </div>

          </div>

        </div>
        <div className='service-section'>
          <div className='service' data-aos="fade-up">
              <div class="image-container">
                  <div class="image top-left" >
                    <img src='../Images/pharmacy2.jpg'></img>
                  </div>
                  <div class="image" >
                    <img src='../Images/about3.jpg'></img>
                  </div>
                  <div class="image top-right" >
                    <img src='../Images/maternity3.jpg'></img>
                  </div>
              </div>            
              <div className='content'>
                <h3>Pharmacy: Welcome to our pharmacy</h3>
                <p>Our pharmacy is committed to providing high-quality medication and pharmaceutical services to all our patients. Whether you're picking up a prescription or seeking advice on medication management, our experienced pharmacists are here to help.We prioritize patient safety, satisfaction, and well-being. Our pharmacists work closely with healthcare providers to ensure accurate medication therapy and promote optimal health outcomes for all our patients.</p>
                <p>Services we offer:</p>
                <ol>
                  <li>Prescription dispensing</li>
                  <li>Over-the-counter medications</li>
                  <li>Medication counseling and education</li>
                  <li>Immunizations and vaccinations</li>
                  <li>Medication therapy management</li>
                  <li>Medication synchronization</li>
                </ol>
              </div>
          </div>
          <div className='service' data-aos="fade-up">                          
              <div className='content'>
                <h3>Maternity: Welcoming new life</h3>
                <p>At Nyansiongo Maternity & Nursing Home, we understand that the birth of a child is one of life's most precious moments. Our maternity services are designed to provide expectant mothers and their families with exceptional care and support throughout the pregnancy, childbirth, and postpartum journey.</p>
                <p>Services we offer:</p>
                <ol>
                  <li>Prenatal care and education</li>
                  <li>Labor and delivery services</li>
                  <li>Birthing options, including natural childbirth and epidural anesthesia</li>
                  <li>Neonatal care for newborns</li>
                  <li>Breastfeeding support and lactation consultation</li>
                  <li>Postpartum care and follow-up</li>
                </ol>
              </div>
              <div class="image-container">
                  <div class="image top-left" >
                    <img src='../Images/maternity.jpg'></img>
                  </div>
                  <div class="image" >
                    <img src='../Images/maternity2.jpg'></img>
                  </div>
                  <div class="image top-right" >
                    <img src='../Images/mission.jpg'></img>
                  </div>
              </div>
          </div>
          <div className='service' data-aos="fade-up">
              <div class="image-container">
                  <div class="image top-left" >
                    <img src='../Images/lab.jpg'></img>
                  </div>
                  <div class="image" >
                    <img src='../Images/lab2.jpg'></img>
                  </div>
                  <div class="image top-right" >
                    <img src='../Images/lab4.jpg'></img>
                  </div>
              </div>            
              <div className='content'>
                <h3>Laboratory</h3>
                <p>The laboratory at Nyansiongo Maternity & Nursing Home plays a crucial role in patient care by providing accurate and timely diagnostic testing services. Our team of skilled laboratory professionals utilizes advanced technology and rigorous quality control measures to deliver reliable test results for healthcare providers and patients.</p>
                <p>Services we offer:</p>
                <ol>
                  <li>Routine blood tests</li>
                  <li>Urinalysis</li>
                  <li>Microbiology testing</li>
                  <li>Molecular diagnostics</li>
                  <li>Hematology and coagulation studies</li>
                  <li>Clinical chemistry</li>
                </ol>
              </div>
          </div>

        </div>
      </section>
    );
}
export default Service;