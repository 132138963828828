import logo from './logo.svg';
import './App.css';
import Navbar from './components/Navbar';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './components/pages/Home';
import About from './components/pages/About';
import Contact from './components/pages/Contact';
import Service from './components/pages/Service';
import Footer from './components/Footer';
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";



function App() {
  return (
    <>
    
    <Router>
     <Navbar/>
     <Routes>
      <Route path='/' exact Component={Home}/>
      <Route path='/about-us' exact Component={About}/>
      <Route path='/contact' exact Component={Contact}/>
      <Route path='/services' exact Component={Service}/>
     </Routes>
     <Footer/>
    </Router>
    </>
  );
}

export default App;
